body {
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.input::placeholder {
  color: lightgray;
}

.option {
  color: black;
}

@font-face {
  font-family: "PRT-Light";
  src: url("./fonts/Pretendard-Light.woff");
}

@font-face {
  font-family: "PRT-Medium";
  src: url("./fonts/Pretendard-Medium.woff");
}

@font-face {
  font-family: "PRT-SemiBold";
  src: url("./fonts/Pretendard-SemiBold.woff");
}

@font-face {
  font-family: "PRT-ExtraBold";
  src: url("./fonts/Pretendard-ExtraBold.woff");
}
